.pagination {
  margin: 20px auto;
}

.pagination .previous {
  display: none;
}

.pagination .next {
  display: none;
}

.pagination li a {
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 18px;
  background-color: #FFBE00;
}

.pagination li.active a {
  font-weight: bold;
  background-color: black;
  border: 2px solid #FFBE00;
  color: #FFBE00;
}