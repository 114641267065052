.container {
  height: calc(100vh - 38px);
}

.filterMain {
  width: 320px;
}

.filterContainer {
  display: flex;
  height: 38px;
}

.filterContainer1 {
  display: flex;
  align-items: center;
}

.filterField {
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
}

.info {
  display: flex;
  align-items: center;
  width: inherit;
  height: 38px;
  padding-top: 0;
  padding-bottom: 0;
  color: #768191;
  font-size: 0.8rem;
}

.infoText {
  margin-left: 13px;
}

.column {
  height: inherit;
  margin-top: -1px;
  overflow-y: scroll;
  background-color: rgb(255, 255, 255);
}

.verticalLine {
  position: absolute;
  margin-top: 14px;
  margin-left: 34px;
  width: 1px;
  height: 84%;
  background-color: #dadce0;
  z-index: 998;
}

.row:hover {
  background-color: #f3f5f8;
  border-left: 2px solid rgb(177, 177, 177);
  cursor: pointer;
  width: 298px;
}

.rowActive {
  background-color: rgb(233, 233, 233);
  border-left: 2px solid rgb(177, 177, 177);
  cursor: default;
  width: 298px;
}

.dropDown {
  z-index: 2011;
}
