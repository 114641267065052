.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 50px;
  opacity: 1;
  padding: 0 20px;
}

.row {
  display: flex;
  align-items: center;
}

.text {
  font-family: "Roboto" sans-serif;
  font-size: 0.8rem;
  color: #1d2329;
}

.time {
  height: 50%;
  font-family: "Roboto" sans-serif;
  font-size: 0.7rem;
  color: #768191;
}

.address {
  height: 50%;
  font-family: "Roboto" sans-serif;
  font-size: 0.7rem;
  color: #adb6c3;
}

.icon {
  width: 12px;
  height: 12px;
  margin-left: 10px;
  filter: invert(56%) sepia(2%) saturate(2451%) hue-rotate(175deg)
    brightness(95%) contrast(76%);
}

.stop {
  font-family: "Roboto" sans-serif;
  font-size: 0.7rem;
  color: white;
}
