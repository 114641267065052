.container {
    display: flex;
    justify-content: space-between;
    height: 116px;
    background-color: white;
    font-family: "Roboto", sans-serif;
    margin: 30px 30px 15px 30px;
    box-shadow: 0px 2px 2px #0000003D;
}

.iconArea {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.6;
}

.iconArea img {
    height: 37px;
    filter: invert(97%) sepia(87%) saturate(3%) hue-rotate(217deg) brightness(103%) contrast(100%);
}

.infoArea {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    flex: 1;
}

.infoArea div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    white-space: nowrap;
}

.number {
    height: 50px;
    margin: 0;
    padding: 0;
    font-size: 3rem;
    font-weight: 900;
}